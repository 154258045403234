
import {
  defineComponent,
  inject,
  ref,
  watch,
  reactive,
  Ref,
  nextTick,
  onMounted,
} from "vue";
import { useI18n } from "vue-i18n";
import { AxiosStatic } from "axios";
import { notification } from "ant-design-vue";
import { get, cloneDeep } from "lodash";
import { EditOutlined } from "@ant-design/icons-vue";
import { UserProfile, RuleObjectExt } from "../../types";
import { PhoneNumberListItem, PhoneNumber } from "@hd2/common/types";
import { isValidPostcode } from "@hd2/common/src/utils";
import { usePermissions } from "../composable/usePermissions";
import { actions } from "../utils/const";
import PhoneNumberComponent from "@hd2/common/src/components/PhoneNumber.vue";
import phonePrefixes from "@hd2/common/src/utils/phonePrefixes.json";
import { Form } from "ant-design-vue";

const useForm = Form.useForm;

interface Model {
  address: {
    city: string;
    postCode: string;
    streetName: string;
    streetNumber: string;
    flatNumber: string;
  };
  email: string;
  phoneNumber: PhoneNumber;
}

const phonePrefixOptions: Record<string, PhoneNumberListItem> = phonePrefixes;

export const ProfileComponent = defineComponent({
  components: {
    EditOutlined,
    "phone-number": PhoneNumberComponent,
  },
  setup() {
    const http = inject("http") as AxiosStatic;
    const { t } = useI18n();
    const { hasPermission } = usePermissions();

    const valid: Ref<boolean> = ref(false);
    const model: Model = reactive({
      address: {
        city: "",
        postCode: "",
        streetName: "",
        streetNumber: "",
        flatNumber: "",
      },
      email: "",
      phoneNumber: {
        pattern: "",
        prefix: "+48",
        number: "",
      },
    });
    const loadingUserProfile: Ref<boolean> = ref(false);
    const updatingUserProfile: Ref<boolean> = ref(false);
    const data: UserProfile = reactive({
      dateOfBirth: "",
      firstName: "",
      id: "0",
      lastName: "",
      pesel: "",
      providerId: "",
      termsAgreement: true,
      address: {
        city: "",
        postCode: "",
        streetName: "",
        streetNumber: "",
        flatNumber: "",
      },
      email: "",
      phoneNumber: "",
      phoneNumberPrefix: "48",
      nfzActive: false,
    });
    const toggleMode: Ref<string> = ref("");

    const rules: Record<string, Array<RuleObjectExt>> = {
      email: [
        {
          required: true,
          pattern: new RegExp(/^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/),
          message: t("PROFILE.CONTACT_DATA.EMAIL_REQUIRED"),
        },
      ],
      phoneNumber: [
        {
          validator: (rule, value: PhoneNumber) => {
            return new Promise((resolve, reject) => {
              if (!value.number) {
                reject(t("PROFILE.CONTACT_DATA.PHONE_NUMBER_REQUIRED"));
              } else if (!value.number.match(value.pattern)) {
                reject(t("PROFILE.CONTACT_DATA.PHONE_NUMBER_INVALID"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      "address.city": [
        {
          type: "string",
          validator: () => {
            return new Promise((resolve, reject) => {
              if (!model.address.city) {
                reject(t("PROFILE.CONTACT_DATA.CITY_REQUIRED"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      "address.postCode": [
        {
          type: "string",
          validator: () => {
            return new Promise((resolve, reject) => {
              if (!isValidPostcode(model.address.postCode)) {
                reject(t("PROFILE.CONTACT_DATA.POST_CODE_REQUIRED"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      "address.streetName": [
        {
          type: "string",
          validator: () => {
            return new Promise((resolve, reject) => {
              if (!model.address.streetName) {
                reject(t("PROFILE.CONTACT_DATA.STREET_NAME_REQUIRED"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      "address.streetNumber": [
        {
          type: "string",
          validator: () => {
            return new Promise((resolve, reject) => {
              if (!model.address.streetNumber) {
                reject(t("PROFILE.CONTACT_DATA.STREET_NUMBER_REQUIRED"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
    };

    const form = useForm(model, rules);

    const formTemplate = ref();

    const onPhoneNumberChange = () => {
      formTemplate.value.validate("phoneNumber");
    };

    const checkForm = () => {
      return form
        .validate()
        .then(() => {
          valid.value = true;
        })
        .catch(() => {
          valid.value = false;
        });
    };

    watch(
      model,
      () => {
        nextTick(() => {
          checkForm();
        });
      },
      { deep: true }
    );

    const getUserProfile = async () => {
      loadingUserProfile.value = true;
      try {
        const getProfileRes = await http
          .get(`patient-portal/api/patient-profile`)
          .then((res) => res.data);

        data.firstName = getProfileRes.firstName;
        data.lastName = getProfileRes.lastName;
        data.pesel = getProfileRes.pesel;
        data.dateOfBirth = getProfileRes.dateOfBirth;
        data.id = getProfileRes.id;
        data.pesel = getProfileRes.pesel;
        data.providerId = getProfileRes.providerId;
        data.termsAgreement = getProfileRes.termsAgreement;
        data.address = getProfileRes.address || data.address;
        data.email = getProfileRes.email;
        data.phoneNumber = getProfileRes.phoneNumber;
      } catch {
        notification.open({
          message: t("ERROR.4212"),
          class: "error",
        });
      }
      loadingUserProfile.value = false;
    };
    const updateUser = async (field: string) => {
      const mapField = {
        phoneNumber: `${model.phoneNumber.prefix}${model.phoneNumber.number}`,
        email: model.email,
        address: model.address,
      };

      updatingUserProfile.value = true;
      try {
        const updateUserRes = await http
          .put(`patient-portal/api/patient-profile`, {
            [field]: mapField[field as keyof Model],
          })
          .then((res) => res.data);

        data[field as keyof Model] = updateUserRes[field];
        notification.open({
          message: t("PROFILE.SUCCESS"),
          class: "success",
        });

        toggleMode.value = "";
      } catch {
        notification.open({
          message: t("ERROR.4214"),
          class: "error",
        });
      }
      updatingUserProfile.value = false;
    };

    const edit = (field: string) => {
      toggleMode.value = field;
    };
    const cancel = (field: string) => {
      toggleMode.value = "";
      model[field as keyof Model] = cloneDeep(get(data, field));
    };

    onMounted(async () => {
      await getUserProfile();
      model.address = cloneDeep(data.address);
      model.email = data.email;
      model.phoneNumber = {
        number: data.phoneNumber,
        prefix: `+${data.phoneNumberPrefix}`,
        pattern: phonePrefixOptions[`+${data.phoneNumberPrefix}`].pattern,
      };
    });

    return {
      cancel,
      edit,
      actions,
      hasPermission,
      checkForm,
      updateUser,
      form,
      formTemplate,
      valid,
      model,
      rules,
      updatingUserProfile,
      toggleMode,
      onPhoneNumberChange,
      data,
      loadingUserProfile,
      t,
    };
  },
});
export default ProfileComponent;
